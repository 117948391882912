import { useEffect, useState } from 'react';
import { BarChart2, Map } from 'react-feather';
import { formatDate } from '../../../../utils';
import QRCode from 'qrcode.react';
import Box from '../../../../components/atoms/box';
import Subtitle from '../../../../components/atoms/subtitle';
import Table from '../../../../components/molecules/table';
import TableVertical from '../../../../components/molecules/table-vertical';
import Link from '../../../../components/atoms/link';
import Text from '../../../../components/atoms/text';
import BatchApi from '../../../../api/batch';

const productMeta = [
  { name: 'Type', key: 'product.type' },
  { name: 'Code', key: 'product.code' },
  { name: 'Nom', key: 'product.name' },
  { name: 'EAN', key: 'product.ean' },
  { name: 'Date d\'enregistrement', key: 'product.createdAt', format: value => formatDate(value) }
];

const batchMeta = [
  { name: 'Code', key: 'code' },
  { name: 'Code fournisseur', key: 'codeSupplier' },
  { name: 'Type d\'ordre', key: 'codeOrderType' },
  { name: 'Numéro d\'ordre', key: 'codeOrder' },
  { name: 'Date d\'enregistrement', key: 'createdAt', format: value => formatDate(value) }
];

const movementMeta = [
  { name: 'Type', key: 'type' },
  { name: 'Acteur', key: 'entity.name' },
  { name: 'Dépôt', key: 'site.name' },
  { name: 'Date', key: 'time', format: value => formatDate(value) },
  { name: 'Date d\'enregistrement', key: 'createdAt', format: value => formatDate(value), enableOrder: true }
];

const documentMeta = [
  { name: 'Type', key: 'type' },
  { name: 'Code', key: 'code' },
  { name: 'Nom', key: 'name' },
  { name: 'Chiffré', key: 'crypted' }
];

const childMeta = [
  { name: 'Type', size: 1, key: 'child.product.type' },
  { name: 'Code', size: 1, key: 'child.code' },
  { name: 'Code fournisseur', size: 1, key: 'child.codeSupplier', enableOrder: true },
  { name: 'Numéro d\'ordre', size: 1, key: 'child.codeOrder', enableOrder: true },
  { name: 'Nom', size: 3, key: 'child.product.name' },
  { name: 'Quantité', size: 1, key: 'child.quantity' }
];

const parentMeta = [
  { name: 'Type', size: 1, key: 'parent.product.type' },
  { name: 'Code', size: 1, key: 'parent.code' },
  { name: 'Code fournisseur', size: 1, key: 'parent.codeSupplier', enableOrder: true },
  { name: 'Numéro d\'ordre', size: 1, key: 'parent.codeOrder', enableOrder: true },
  { name: 'Nom', size: 3, key: 'parent.product.name' },
  { name: 'Quantité', size: 1, key: 'parent.quantity' }
];

const BatchDetails = ({ id }) => {
  const [batch, setBatch] = useState();

  useEffect(
    () => {
      BatchApi.findOne(id)
        .then(response => setBatch(response.data));
    },
    [id]
  );

  return (
    <Box flex="1">
      <Box display="flex" margin="0 0 32px 0">
        <Box
          display="flex"
          flex="1"
          padding="20px"
          backgroundColor="rgba(100, 100, 100, 0.11)"
          borderRadius="16px"
          margin="0 40px 0 0"
        >
          {
            batch && batch.product && batch.product.type === 'product' &&
            (
              <Box margin="0 20px 0 0">
                <QRCode
                  value={`https://www.clarins.fr/on/demandware.store/Sites-clarinsfr-Site/fr_FR/Product-Show?pid=%20${batch.product.code}%20`}
                  size={ 96 }
                  bgColor="rgba(100, 100, 100, 0)"
                  renderAs="svg"
                />
              </Box>
            )
          }
          <Box>
            <Box margin="0 0 5px 0">
              <Subtitle color="#646464">Produit</Subtitle>
            </Box>
            <TableVertical
              meta={productMeta}
              rows={batch && [batch]}
            />
          </Box>
        </Box>
        <Box
          flex="1"
          padding="20px 25px"
          backgroundColor="rgba(100, 100, 100, 0.11)"
          borderRadius="16px"
        >
          <Box margin="0 0 5px 0">
            <Subtitle color="#646464">Lot</Subtitle>
          </Box>
          <TableVertical
            meta={batchMeta}
            rows={batch && [batch]}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" margin="0 0 5px 0">
        <Box>
          <Subtitle color="#646464">Traçage du lot</Subtitle>
        </Box>
        <Box>
          <Link href={`/batch/map/${id}`} basePath="/dashboard">
            <Box display="flex" alignItems="center">
              <Box margin="0 5px 0 0">
                <Map color="#BE0F34" size="18" />
              </Box>
              <Box margin="0 10px 0 0">
                <Text fontSize="0.75rem" color="#BE0F34">
                  Afficher la carte
                </Text>
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>
      <Box margin="0 0 32px 0">
        <Table
          meta={movementMeta}
          defaultRows={batch && batch.movement}
        />
      </Box>
      <Box margin="0 0 5px 0">
        <Subtitle color="#646464">Documents</Subtitle>
      </Box>
      <Box margin="0 0 32px 0">
        <Table
          meta={documentMeta}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" margin="0 0 5px 0">
        <Box>
          <Subtitle color="#646464">Issu de</Subtitle>
        </Box>
        <Box display="flex" alignItems="center">
          <Link href={`/batch/graph/${id}?direction=both`} basePath="/dashboard">
            <Box display="flex" alignItems="center">
              <Box margin="0 5px 0 0">
                <BarChart2 color="#BE0F34" size="18" />
              </Box>
              <Box margin="0 20px 0 0">
                <Text fontSize="0.75rem" color="#BE0F34">
                  Afficher le graphique complet
                </Text>
              </Box>
            </Box>
          </Link>
          <Link href={`/batch/graph/${id}?direction=children`} basePath="/dashboard">
            <Box display="flex" alignItems="center">
              <Box margin="0 5px 0 0">
                <BarChart2 color="#BE0F34" size="18" />
              </Box>
              <Box margin="0 10px 0 0">
                <Text fontSize="0.75rem" color="#BE0F34">
                  Afficher le graphique descendant
                </Text>
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>
      <Box margin="0 0 32px 0">
        <Table
          meta={childMeta}
          defaultRows={batch && batch.childs}
          enableClickable
          defaultUrl="/dashboard/batch/details"
          defaultClickableField="batchFromId"
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" margin="0 0 5px 0">
        <Box>
          <Subtitle color="#646464">Utilisé dans</Subtitle>
        </Box>
        <Box display="flex" alignItems="center">
          <Link href={`/batch/graph/${id}?direction=both`} basePath="/dashboard">
            <Box display="flex" alignItems="center">
              <Box margin="0 5px 0 0">
                <BarChart2 color="#BE0F34" size="18" />
              </Box>
              <Box margin="0 20px 0 0">
                <Text fontSize="0.75rem" color="#BE0F34">
                  Afficher le graphique complet
                </Text>
              </Box>
            </Box>
          </Link>
          <Link href={`/batch/graph/${id}?direction=parent`} basePath="/dashboard">
            <Box display="flex" alignItems="center">
              <Box margin="0 5px 0 0">
                <BarChart2 color="#BE0F34" size="18" />
              </Box>
              <Box margin="0 10px 0 0">
                <Text fontSize="0.75rem" color="#BE0F34">
                  Afficher le graphique montant
                </Text>
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>
      <Box margin="0 0 32px 0">
        <Table
          meta={parentMeta}
          defaultRows={batch && batch.parents}
          enableClickable
          defaultUrl="/dashboard/batch/details"
          defaultClickableField="batchToId"
        />
      </Box>
    </Box>
  );
};

export default BatchDetails;