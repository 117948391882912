import Api from '../index';
import Default from '../default';

const route = '/batch';

const batchApi = {
	tree(id, direction) {
		return Api.get(`${route}/tree/${id}`, { params: { direction } });
	},

  ...Default(route)
};

export default batchApi;