import Styled from 'styled-components';

const Button = Styled.button`
  font-size: 1rem;
  font-weight: 400;
  border-radius: 16px;
  border: none;
  padding: 10px 26px;
  background-color: #BE0F34;
  color: #FFF;
  user-select: none;
  cursor: pointer;
`;

export default Button;