import Box from '../../../components/atoms/box';
import Table from '../../../components/molecules/table';
import EntityApi from '../../../api/entity';

const entityMeta = [
  { name: 'Type', size: 1, key: 'type', enableOrder: true },
  { name: 'Code', size: 1, key: 'code', enableOrder: true },
  { name: 'Nom', size: 2, key: 'name', enableOrder: true }
];

const filter = [
  {
    name: 'Type',
    key: 'type',
    options: [
      { name: 'supplier', value: 'supplier' },
      { name: 'manufacturer', value: 'manufacturer' },
      { name: 'transporter', value: 'transporter' }
    ]
  }
];

const Batch = () => {
  return (
    <Box flex="1" margin="0 0 32px 0">
      <Table
        api={EntityApi}
        meta={entityMeta}
        defaultOrder="-id"
        defaultLimit={20}
        defaultUrl="/dashboard/entity/details"
        defaultFilter={filter}
        enableFilter
        enablePagination
        enableClickable
      />
    </Box>
  );
};

export default Batch;