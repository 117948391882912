import { useQueryParams } from 'raviger';
import { formatDate } from '../../../utils';
import Box from '../../../components/atoms/box';
import Table from '../../../components/molecules/table';
import BatchApi from '../../../api/batch';

const batchMeta = [
  { name: 'Type', size: 1, key: 'product.type', enableOrder: true },
  { name: 'Code', size: 1, key: 'code', enableOrder: true },
  { name: 'Code fournisseur', size: 1, key: 'codeSupplier', enableOrder: true },
  { name: 'Numéro d\'ordre', size: 1, key: 'codeOrder', enableOrder: true },
  { name: 'Nom', size: 3, key: 'product.name', enableOrder: true },
  { name: 'Quantité', size: 1, key: 'quantity', enableOrder: true },
  { name: 'Date d\'enregistrement', size: 1.2, key: 'createdAt', format: value => formatDate(value), enableOrder: true }
];

const filter = [
  {
    name: 'Type',
    key: 'product.type',
    options: [
      { name: 'raw', value: 'raw' },
      { name: 'plant', value: 'plant' },
      { name: 'material', value: 'material' },
      { name: 'sauce', value: 'sauce' },
      { name: 'product', value: 'product' }
    ]
  }
];

const Details = () => {
  const [query] = useQueryParams();

  return (
    <Box flex="1" margin="0 0 32px 0">
      <Table
        api={BatchApi}
        meta={batchMeta}
        defaultWhere={query}
        defaultOrder="-id"
        defaultLimit={20}
        defaultFilter={filter}
        enableFilter
        enablePagination
        enableClickable
        defaultUrl="/dashboard/batch/details"
      />
    </Box>
  );
};

export default Details;